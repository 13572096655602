/* eslint-disable quote-props, max-len */
export default {
  '@colon': '{{ s }}：',
  '@tip_input': '请输入 {{ s }}',
  '@tip_input_valid': '请输入正确的 {{ s }}',
  '@tip_select': '请选择 {{ s }}',

  // detail form
  'Web page URL': '实验URL',
  'Exact Match': '精准匹配',
  'Fuzzy Match': '模糊匹配',
  'Add group': '添加版本',
  'Url should be https protocol': 'URL 需要是 https 协议',

  // detail item
  '@enter_editor': '进入编辑器',
  '@n_changes': '{{count}} 处修改',
  '@preview_version': '预览版本',

  // editor page
  '@mode_edit': '编辑模式',
  '@mode_preview': '预览模式',
  'Prev Step': '上一步',
  'Next Step': '下一步',
  'Save': '保存',
  '@device_desktop': '桌面端',
  '@device_mobile': '移动端',
  'Please choose group': '请选择实验版本',
  '@save_success_tip': '保存成功，若编辑完成，请返回上一个标签页进入「下一步」',
  'Integration code has been configured.': '已配置集成代码',
  'Integration code has not been configured.': '未配置集成代码',
  'The control version is for experimental purposes and cannot be modified.': '对照版本为实验参考对象，不可修改',
  'Please select an element first.': '请先点选一个元素',

  // 编辑器右侧
  // 元素变量组
  'Element Variable': '元素变量',
  // 元素类型：
  'Element Type': '元素类型',
  'Text': '文本',
  'Image': '图片',
  'Container': '容器',
  'Unknown': '未知',
  'Control (SELECTOR)': '控件（SELECTOR）',
  'Placeholder': '提示文字',
  // 字符：
  'Character': '字符',
  'Size': '字号',
  'Line Height': '行高',
  '@font_color': '文字颜色',
  // 对齐方式：
  'Alignment:': '对齐方式：',
  // 背景填充：
  'Background Fill': '背景填充',
  'Background Color': '纯色背景',
  'Background Image': '背景图片',
  // 边框：
  'Border': '边框',
  'Color': '颜色',
  'Width': '宽度',
  'Radius': '圆角',
  'Style': '样式',
  'Border Style': '边框样式',
  // 阴影：
  'Shadow': '阴影',
  'Box Shadow': '元素阴影',
  'Text Shadow': '文字阴影',
  // 盒模型：
  'Box Model': '盒模型：',
  // 位置调整组
  'Adjust Position': '位置调整',
  'Move Element': '移动元素',
  'Reflow': '重排',
  'Position': '任意位置',
  'Start Move': '开始移动',
  'Please choose an element': '请选择一个元素',
  'Moving': '正在移动',

  // SDK 检查机制
  'Detected that you have not configured the integration code': '检测到您未配置集成代码',
  'Please copy the integration code and paste it before the HEAD tag in the HTML code of the experiment page.': '请复制集成代码并粘贴在实验页面 HTML 代码的 </head> 之前',
  'Copy the integration code': '复制集成代码',
  'Checking': '正在检查',
  'Configuration completed': '已完成配置',
  'Copy successful': '复制成功',
};
