/*
 * 由于历史将 $t 直接作为全局的方法，必须在任何业务 js 执行前先确保 i18n 可用
 * 将原本的 main 分为两部分
 * 1. 与 i18n 无关的，尽量提前加载和初始化。小心区分其依赖内部是否引用到 $t()
 * 2. app 入口。包括各 constants 其内部可能也用了 $t）
 * 将增加白屏时间
 */

// 确保排在最前
import '@/mod/aegis';

import { initI18n } from '@/i18n';

const initI18nTask = initI18n();

// 不等待，main-after-pre 里有实际的静态 import
Promise.allSettled([
  // 提前加载的资源名单，尽量减少 main-after-pre 才加载的延迟时间
  import('./main-auth0-plugin'), // auth0 内部有异步请求，尽量早加载
  import('@/styles/element-index.scss'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import('@tencent/bk-icon-abetterchoice-v2'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import('@tencent/bk-icon-abetterchoice-v2/src/index'),
  import('vue'),
  import('vue-router'),
  import('pinia'),
  import('element-plus'),
  import('@element-plus/icons-vue'),
  import('@tencent/ti18n-vue3-sdk'),
  import('wujie-vue3'),
  import('@tencent/tab-ui-plus'),
  import('dayjs'),
  import('dayjs/plugin/duration'),
]);

(async () => {
  await initI18nTask;

  await Promise.all([
    import('dayjs'),
    import('dayjs/plugin/duration'),
  ]).then(([dayjs, duration]) => {
    dayjs.extend(duration.default);
  });

  await import('./main-after-pre');
})();
