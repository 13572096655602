import { lazyGetter } from '@tencent/tab-lib';

export enum OpTypes {
  STYLE = 'style',
  ATTRIBUTE = 'attribute',
  MOVE = 'move',
  EMPTY = '',
}

export const VARIANT_COLORS = [
  '#2E7AEA',
  '#F0AE24',
  '#25BEAF',
  '#A04CF3',
  '#26A0CC',
  '#F6558F',
  '#F87E55',
  '#E75ADE',
];

export enum MoveStatus {
  UN_STARTED,
  WAIT_TO_SELECT,
  MOVING,
  MOVED,
}

export enum MoveType {
  Reorder = 'reorder',
  Anywhere = 'anywhere',
}

export const MOVE_STATUS_LABEL = lazyGetter(() => ({value: [
  $tuiexpp('Start Move'),
  $tuiexpp('Please choose an element'),
  $tuiexpp('Moving'),
  $t('Confirm'),
]}));

export const BORDER_STYLE = [
  'dashed',
  'dotted',
  'double',
  'groove',
  'hidden',
  'inherit',
  'initial',
  'inset',
  'none',
  'outset',
  'revert',
  'ridge',
  'solid',
  'unset',
];

export const DEFAULT_PROPS = {
  tagName: '',
  selector: '',
  href: '',
  src: '',
  classList: '',
  placeholder: '',
  textContent: '',
  value: '',
  color: '',
  backgroundColor: '',
  backgroundImage: '',
  width: '',
  height: '',
  fontFamily: '',
  fontSize: '',
  fontWeight: '',
  textAlign: '',
  textDecoration: '',
  fontStyle: '',
  lineHeight: '',
  borderRadius: '',
  borderWidth: '',
  borderColor: '',
  borderStyle: '',
  boxShadow: '',
  textShadow: '',
  marginTop: '',
  marginRight: '',
  marginBottom: '',
  marginLeft: '',
  borderTop: '',
  borderRight: '',
  borderBottom: '',
  borderLeft: '',
  paddingTop: '',
  paddingRight: '',
  paddingBottom: '',
  paddingLeft: '',
};
