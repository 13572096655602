// 小心处理，有循环依赖
import { ENV_TAG_MAP, devTool } from '@/dev-tool';

import { env as rawEnv } from './current';
export * from './type';

export const env = (() => {
  if (!devTool.usable || !devTool.envTag.initialValue) {
    return { ...rawEnv };
  }

  const envTagInfo = ENV_TAG_MAP[devTool.envTag.initialValue];
  return {
    ...rawEnv,
    deployEnv: envTagInfo.deployEnv,
    bizPlatform: envTagInfo.bizPlatform,
    apiHost: envTagInfo.apiHost,
  };
})();
