import EventEmiiter from 'events';
import { IOp, IVariantConfig } from './model';

export const RANDOM_LENGTH = 5;

export const CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

// 生成随机字符串
export const makeId = (): string => {
  let result = '';
  const charactersLength = CHARACTERS.length;
  for (let i = 0; i < RANDOM_LENGTH; i++) {
    result += CHARACTERS.charAt(Math.floor(Math.random()
      * charactersLength));
  }
  return result;
};

// 生成连续数字
export const genNumbers = (from: number, to: number): number[] => {
  const numbs: number[] = [];
  for (let i = from; i <= to; i++) {
    numbs.push(i);
  }
  return numbs;
};

// 判断字符串是否纯数字
export const isInteger = (str: string): boolean => /^-{0,1}\d+$/.test(str);

export const getSDKOp = (op: IOp): IVariantConfig => {
  const mOption: IVariantConfig = {
    selector: '',
    attribute: [],
    op: [],
  };
  mOption.selector = op.selector || '';
  enhanceTextContent(mOption, op);
  enhanceClassName(mOption, op);
  enhanceStyle(mOption, op);
  enhanceAttribute(mOption, op);
  enhanceMove(mOption, op);
  return mOption;
};

const enhanceTextContent = (config: IVariantConfig, op: IOp) => {
  if (op.key !== 'text-content') {
    return;
  }
  config.attribute.push({
    key: 'text',
    value: op.value as string,
  });
};

const enhanceClassName = (config: IVariantConfig, op: IOp) => {
  if (op.key !== 'className') {
    return;
  }
  config.attribute.push({
    key: 'className',
    value: op.value as string,
  });
};

/* url校验规则-初级 */
const URL_PATTERN_SIMPLE = new RegExp('^(https?:\\/\\/).+', 'i');

const enhanceStyle = (config: IVariantConfig, op: IOp) => {
  if (op.type !== 'style') {
    return;
  }
  if (op.key === 'background-image') {
    if (URL_PATTERN_SIMPLE.test(String(op.value))) {
      config.attribute.push({
        key: 'style',
        value: `background-image:url("${op.value}") !important;`,
      });
    } else if (!op.value) {
      config.attribute.push({
        key: 'style',
        value: 'background-image:none !important;',
      });
    } else {
      config.attribute.push({
        key: 'style',
        value: `background-image:${op.value} !important;`,
      });
    }
  } else {
    config.attribute.push({
      key: 'style',
      value: `${camelToKeb(op.key as string)}:${op.value} !important;`,
    });
  }
};

const enhanceAttribute = (config: IVariantConfig, op: IOp) => {
  if (op.type !== 'attribute') {
    return;
  }
  config.attribute.push({
    key: op.key!,
    value: op.value!,
  });
};

const enhanceMove = (config: IVariantConfig, op: IOp) => {
  if (op.type !== 'move') {
    return;
  }
  if (op.moveType === 'reorder') {
    config.op.push({
      type: op.type,
      targetSelector: op.targetSelector,
      insertOrder: op.insertOrder,
      newSelector: op.newSelector,
    });
  } else {
    config.attribute.push({
      key: 'style',
      value: 'position:relative !important;',
    });
    config.attribute.push({
      key: 'style',
      value: `left:${op.x};`,
    });
    config.attribute.push({
      key: 'style',
      value: `top:${op.y};`,
    });
  }
};

// camel-case转kebab-case
export const camelToKeb = (camelCasedStr: string): string => {
  const str = camelCasedStr.replace(/[A-Z]/g, i => `-${i.toLowerCase()}`);
  return str;
};

export const pixel2number = (value: string) => {
  const val = parseFloat(value);
  if (isNaN(val)) {
    return 0;
  }
  return val;
};


/**
 * 清楚统一属性上的修改，以后入栈的为准
 * @param config SDK 配置
 */
export const zipSDKOp = (config: IVariantConfig[]): IVariantConfig[] => {
  const map: Map<string, number> = new Map();
  const result: IVariantConfig[] = config.reduce((acc: IVariantConfig[], item: IVariantConfig): IVariantConfig[] => {
    const { selector } = item;
    if (map.has(selector)) {
      const index = map.get(selector);
      const target = acc[index!];
      target.attribute.push(...item.attribute);
      target.op.push(...item.op);
    } else {
      const { length } = acc;
      acc.push(item);
      map.set(selector, length);
    }
    return acc;
  }, []);
  compressConfig(result);
  return result;
};

/**
 * 对操作进行压缩
 */
const compressConfig = (configs: IVariantConfig[]): void => {
  for (const config of configs) {
    Object.assign(config, {
      attribute: compressAttribute(config),
    });
  }
};

/**
 * 压缩属性
 */
const compressAttribute = (config: IVariantConfig): Record<string, string | number>[] => {
  const styleMap: Map<string, string> = new Map();
  const otherMap: Map<string, string | number> = new Map();
  for (const attr of config.attribute) {
    const { key, value } = attr;
    if (key === 'style') {
      const { styleKey, styleValue } = splitStyle(value as string);
      styleMap.set(styleKey, styleValue);
    } else {
      otherMap.set(key as string, value);
    }
  }
  const result: Record<string, string | number>[] = [];
  for (const [key, value] of otherMap.entries()) {
    result.push({ key, value });
  }
  for (const [key, value] of styleMap.entries()) {
    result.push({
      key: 'style',
      value: `${key}:${value}`,
    });
  }
  return result;
};

interface IStyle {
  styleKey: string;
  styleValue: string;
}

const splitStyle = (str: string): IStyle => {
  const arr: string[] = str.split(':');
  const styleKey = arr.shift() || '';
  const styleValue = arr.join('');
  return {
    styleKey,
    styleValue,
  };
};

// 属性面板事件总线
export const propsEventHub = new EventEmiiter();

// 移动控制面板事件总线
export const controlEventHub = new EventEmiiter();
