import zh from './zh';
import en from './en';

const ns = 'ui-exp-plugin';

let nsSeparator: string;
export const registerAndGetPluginI18nConfig = (argNsSeparator: string) => {
  nsSeparator = argNsSeparator;
  return {
    ns,
    zh,
    en,
  };
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const $tp = (key: keyof typeof zh | string, ...rest: any) => $t(`${ns}${nsSeparator}${key}`, ...rest);

// 临时使用，待工程拆分后有独立的构建流了再调整为 auto import $tp
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).$tuiexpp = $tp;
