/* eslint-disable quote-props, max-len */
export default {
  '@colon': '{{ s }}: ',
  '@tip_input': 'Please input {{ s }}',
  '@tip_input_valid': 'Please input valid {{ s }}%',
  '@tip_select': 'Please choose {{ s }}',
  '@enter_editor': 'Edit',
  '@n_changes': '{{count}} Changes',
  '@preview_version': 'Preview',
  '@mode_edit': 'Edit',
  '@mode_preview': 'Preview',
  '@device_desktop': 'Desktop',
  '@device_mobile': 'Mobile',
  '@save_success_tip': 'Save successfully. If the editing is completed, please return to the previous tab and proceed to "Next".',
  '@font_color': 'Color',
};
