import { Platform, getPlatform } from '@tencent/tab-ui-plus';

const TAB_SDK_VERSION = '3.0.5';
const ABC_SDK_VERSION = '1.0.7';

/* eslint-disable max-len */

export const SNIPPET_TEMPLATE =  (getPlatform() === Platform.TAB ? `
<!-- 上报实验数据，可选 -->
<script src="https://beacon.cdn.qq.com/sdk/4.5.9/beacon_web.min.js"></script>
<!-- Experiment SDK -->
<script src="https://res.ab.qq.com/tab/${TAB_SDK_VERSION}/tab_jssdk.min.js"></script>
<script type="text/javascript">
  wabt.init({
    appId: "api token",
    guid: "uid or uuid", // 未登录时为 uuid 等能识别访客的编号，已登录时为用户 uid
    enableUI: true, // 启用 UI 实验
    onSuccess: function () {
      // 初始化成功回调，可获取相关实验数据
    },
    onFail: function (errMsg) {
      // 初始化失败回调
    },
  });
</script>
  ` : `
<!-- Experiment SDK -->
<script src="https://res.ab.qq.com/abc/${ABC_SDK_VERSION}/abc_websdk.min.js"></script>
<script type="text/javascript">
abc.init(
  {
    projectId: "PROJECT_ID", // Required. This is the project ID of your ABC project.
    unitId: "UNIT_ID", // Required. This is the User Identity ID, used for traffic splitting.
    secretKey: "API_SECRET_KEY", // Required for authentication.
    enableUI: true, // Required for enabled ui experiment.
    enableAutomaticPoll: true, // Optional. Default value is true. When set as true, the experiment and feature flag data will be polled and updated every 10 minutes.
    enableAutomaticExposure: false, // Optional. Default value is false. When set as true, the exposure data will automatically be logged when online retrieval APIs are called.
  }).then((initResult) => {
  console.log(\`ABC SDK initialization result: $\{initResult}\`);
});
</script>
  `)
  .trim();


export const getRawCode = (encodedCode: string) => encodedCode.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
